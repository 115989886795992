import React from 'react'
import "./AnimationReact.css";
import IMG1 from '../../assets/HAU-removebg.png'

const AnimationReact = () => {
  return (
   <div className="main">
   <div className="box">
   <img alt='logo' src={IMG1}></img>
    </div>
   </div>
  )
}

export default AnimationReact